import styles from './tableOfPayments.module.scss'
import {useEffect, useState} from "react";
import {useTariffs} from "../../../shared/services/TariffContext";

export const TableOfPayments = () => {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const { getPaymentHistory } = useTariffs();


    useEffect( () => {
        const fetchPaymentHistory = async () => {
            try {
                const payment = await getPaymentHistory();
                setPaymentHistory(payment);
            } catch (error) {
                console.error('Failed to fetch payment history:', error);
            }
        };

        fetchPaymentHistory();
    }, []);

    const data = [{
        'id': 1,
        'date': '14.08.2023',
        'description': 'Тариф "Заражка"',
        'amount': '650грн',
        'status': 'Успішно'
    },
        {
            'id': 2,
            'date': '14.08.2023',
            'description': 'Тариф "Підкачка"',
            'amount': '570грн',
            'status': 'В очікуванні'
        },
        {
            'id': 3,
            'date': '14.08.2023',
            'description': 'Тариф "Заражка"',
            'amount': '650грн',
            'status': 'Відхилено'
        },
        {
            'id': 4,
            'date': '14.08.2023',
            'description': 'Тариф "Відеореєстрі"',
            'amount': '650грн',
            'status': 'Скасовано'
        },
        {
            'id': 5,
            'date': '14.08.2023',
            'description': 'Тариф "Заражка"',
            'amount': '650грн',
            'status': 'Помилка'
        },
        {
            'id': 6,
            'date': '22.02.2024',
            'description': 'Тариф "Підкачка"',
            'amount': '650грн',
            'status': 'В очікуванні'
        },
        {
            'id': 7,
            'date': '05.12.2024',
            'description': 'Тариф "Заражка"',
            'amount': '500грн',
            'status': 'Скасовано'
        }];

    function getStatusClassName(status) {
        const statusMap = {
            'Успішно': 'td__status_green',
            'В очікуванні': 'td__status_yellow',
            'Відхилено': 'td__status_red',
            'Скасовано': 'td__status_red',
            'Помилка': 'td__status_red'
        };
        return statusMap[status];
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('uk-UA', options);
    }

    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead className={styles.thead}>
                <tr>
                    <th className={`${styles.th} raleway-12-medium`}>№</th>
                    <th className={`${styles.th} raleway-12-medium`}>Дата платежу</th>
                    <th className={`${styles.th} raleway-12-medium`}>Опис</th>
                    <th className={`${styles.th} raleway-12-medium`}>Сума</th>
                    <th className={`${styles.th} raleway-12-medium`}>Статус</th>
                </tr>
                </thead>
                <tbody>
                {paymentHistory.length > 0 && paymentHistory?.map((item, index) => (
                    <tr className={styles.tr} key={index}>
                        <td className={`${styles.td} raleway-12-medium`}>{index + 1}</td>
                        <td className={`${styles.td} raleway-12-medium`}>{formatDate(item.paymentDate)}</td>
                        <td className={`${styles.td} raleway-12-medium`}>{`Тариф ${item.tariffName}`}</td>
                        <td className={`${styles.td} raleway-12-medium`}>{`${item.amount}грн`}</td>
                        <td className={`${styles.td} ${styles.td__status} ${styles[getStatusClassName(item.status)]} raleway-12-medium`}>{item.status}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}
