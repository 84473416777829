import React, {useState} from 'react';
import styles from './feedbackForm.module.scss';

function FeedbackForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        comment: ''
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Тут можна додати логіку для відправлення форми, наприклад, через API.
        console.log(formData);
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <input
                className={'input'}
                type="text"
                name="name"
                placeholder="Твоє ім'я*"
                value={formData.name}
                onChange={handleInputChange}
                required
            />
            <input
                className={'input'}
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleInputChange}
                required
            />
            <input
                className={'input'}
                type="text"
                name="subject"
                placeholder="Тема"
                value={formData.subject}
                onChange={handleInputChange}
            />
            <input
                className={'input'}
                type="text"
                name="comment"
                placeholder="Коментар"
                value={formData.comment}
                onChange={handleInputChange}
            />
            <button className={`${styles.btn} btn-brown`} type="submit">Надіслати</button>
        </form>
    );
}

export default FeedbackForm;
