import React, {useCallback, useRef} from 'react';
import './homePage.module.scss';
import styles from './homePage.module.scss'
import lenaAboutMeSectionImg from '../../images/photos/lena-about-me-section.jpg'
import lenaAboutMeSectionCreattionDemoImg from '../../images/icons/creattion-demo-about-me.svg'
import motivationSectionIcon_1 from '../../images/icons/reasons-to-practice_1.svg'
import motivationSectionIcon_2 from '../../images/icons/reasons-to-practice_2.svg'
import motivationSectionIcon_3 from '../../images/icons/reasons-to-practice_3.svg'
import motivationSectionIcon_4 from '../../images/icons/reasons-to-practice_4.svg'
import motivationSectionIcon_5 from '../../images/icons/reasons-to-practice_5.svg'
import motivationSectionIcon_6 from '../../images/icons/reasons-to-practice_6.svg'
import motivationSectionIcon_7 from '../../images/icons/reasons-to-practice_7.svg'
import motivationSectionCreattionDemoImg from '../../images/icons/creattion-demo-motivation.svg'
import youCanWithMeSectionImg from '../../images/photos/lena-you-can-with-me-section.png'
import exercisesVideoSectionCreattionDemoImg from '../../images/icons/creattion-demo-exercises-video.svg'
import swiperItemUserLogoSvetlana from '../../images/photos/avatar-svetlana.png'
import swiperItemUserLogoYana from '../../images/photos/avatar-yana.png'
import swiperItemResult_1 from '../../images/photos/result_1.jpg'
import swiperItemResult_2 from '../../images/photos/result_2.jpg'
import swiperItemResult_3 from '../../images/photos/result_3.jpg'
import swiperItemResult_4 from '../../images/photos/result_4.jpg'
import swiperItemResult_5 from '../../images/photos/result_5.jpg'
import {TariffsSlider} from "./components/tariffsSlider/tariffsSlider";
import CollapsibleQuestionOftenAskedSection
    from "./components/collapsibleQuestionOftenAsked/collapsibleQuestionOftenAsked";
import collapsibleQuestionOftenAskedSectionCreattionDemoImg
    from '../../images/icons/creattion-demo-collapsible-question-often-asked.svg'
import FeedbackForm from "./components/feedbackForm/feedbackForm";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination} from "swiper/modules";
import {Footer} from "../../../shared/components/footer/footer";

function HomePage() {
    const sliderRef = useRef(null);

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <div className={styles.homePage}>
            <div className={`${styles.heroSection} container`}>
                <h1 className={`${styles.heroSection__h1} montserrat-54-black`}>ПЛАТФОРМА ЗДОРОВИХ І ЩАСЛИВИХ ЛЮДЕЙ</h1>
                <p className={`${styles.heroSection__subtitle} raleway-24-regular`}>Місце, де піклуються про тебе</p>
            </div>
            <div className={`${styles.aboutMeSection} container`} id="aboutMe">
                <img className={styles.img} src={lenaAboutMeSectionImg} alt="LenaAboutMeSectionImg"/>
                <div className={styles.description}>
                    <h2 className={`${styles.description__h2} montserrat-36-black`}>ЗІ МНОЮ ТВОЄ ТІЛО В БЕЗПЕЦІ</h2>
                    <p className={`raleway-16-regular`}>
                        Мене звати Лєна.<span className={`raleway-16-bold`}> Мій досвід роботи більше 10 років. </span>
                        Навчаюся на факультеті фізично-спортивної реабілітації в Україні,
                        <span className={`raleway-16-bold`}> здобуваю спеціальність тренера в коледжі США,</span>
                        а також проходжу багато інших курсів для саморозвитку.
                        <br/><br/>
                        <span className={`raleway-16-bold`}>Матеріал, підхід, подача мого курсу перевірені та
                        затверджені лікарем-травматологом та реабілітологом.</span>
                        <br/><br/>
                        Я є амбасадором зарядок. Сотні тисяч людей отримали мотивацію та покращили своє самопочуття.
                        На перше місце завжди ставлю твоє здоров’я, а не результат будь якою ціною.
                    </p>
                    <img className={styles.img} src={lenaAboutMeSectionCreattionDemoImg} alt="creattionDemo"/>
                </div>
            </div>
            <div className={`${styles.motivationSection} container`} id="motivation">
                <h2 className={`${styles.motivationSection__h2} montserrat-36-black`}>ЛОВИ МОТИВАЦІЮ</h2>
                <div className={`${styles.reasons}`}>
                    <div className={`${styles.col}`}>
                        <div className={`${styles.reasons__item} `}>
                            <img className={styles.img} src={motivationSectionIcon_1} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                        <div className={`${styles.reasons__item}`}>
                            <img className={styles.img} src={motivationSectionIcon_2} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.col}`}>
                        <div className={`${styles.reasons__item}`}>
                            <img className={styles.img} src={motivationSectionIcon_3} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span
                                className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                        <div className={`${styles.reasons__item}`}>
                            <img className={styles.img} src={motivationSectionIcon_4} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span
                                className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                        <div className={`${styles.reasons__item}`}>
                            <img className={styles.img} src={motivationSectionIcon_5} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span
                                className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.col}`}>
                        <div className={`${styles.reasons__item} ${styles.col3}`}>
                            <img className={styles.img} src={motivationSectionIcon_6} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span
                                className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                        <div className={`${styles.reasons__item} ${styles.col3}`}>
                            <img className={styles.img} src={motivationSectionIcon_7} alt="icon"/>
                            <p className={`raleway-16-regular`}>
                                позбудешся болів в тілі, <span
                                className={`raleway-16-bold`}> доведено десятками тисяч учнів</span>
                            </p>
                        </div>
                    </div>
                </div>
                <img className={styles.img} src={motivationSectionCreattionDemoImg} alt="creattionDemo"/>
            </div>
            <div className={`${styles.youCanWithMeSection} container`}>
                <h2 className={`${styles.h2} montserrat-36-black`}>ХОЧЕШ СТАНУ ТВОЇМ ТРЕНЕРОМ?</h2>
                <div className={styles.description}>
                    <div className={styles.imgContainer}>
                        <img className={styles.img} src={youCanWithMeSectionImg} alt="youCanWithMeSectionImg"/>
                        <div className={styles.backgroundImg}></div>
                    </div>
                    <div className={`${styles.subtitles}`}>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Допоможу тобі любити робити щоденну ранкову
                            <span className={`raleway-16-bold`}> зарядку</span>
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Навчу створювати хороший
                            <span className={`raleway-16-bold`}> нейромʼязовий звʼязок</span>
                            , для ефективних тренувань
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Разом зі мною ти
                            <span className={`raleway-16-bold`}> подружишся з фізичним навантаженням </span>
                            та почнем отримувати від нього задоволення
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Без стресу, без болі, без виснажливих до нудоти занять ти
                            <span className={`raleway-16-bold`}> зможеш отримати здорове та красиве тіло</span>
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Ретельно підбираю та
                            <span className={`raleway-16-bold`}> фільтрую кожному вправу, </span>
                            завжди ставлю на 1 місце здоровʼя
                        </p>
                        <p className={`${styles.subtitles__item} raleway-16-regular`}>
                            Я підготувала для тебе лекцію про харчування та прості,
                            <span className={`raleway-16-bold`}> смачні відео-рецепти</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className={`${styles.exercisesVideoSection} container`} id="exercisesVideo">
                <h2 className={`${styles.exercisesVideoSection__h2} montserrat-36-black`}>
                    ЗАПРОШУЮ ЗРОБИТИ ЗІ МНОЮ ЗАРЯДКУ ПРЯМО ЗАРАЗ, СПРОБУЙ!
                </h2>

                <div className={`${styles.exercisesVideoSection__videoContainer}`}>
                    <iframe className={`${styles.video}`}
                            src="https://www.youtube.com/embed/RsKPiv53HL8?si=w99Koq7Cpl4zSZmS"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                    </iframe>
                </div>
                <img className={styles.img} src={exercisesVideoSectionCreattionDemoImg} alt="creattionDemo"/>
            </div>
            <div className={`${styles.studentsFeedbackSection} container`} id="feedback">
                <h2 className={`${styles.studentsFeedbackSection__h2} montserrat-36-black`}>ВІДГУКИ УЧНІВ</h2>
                <div className={styles.swiperContainer}>
                    <Swiper
                        ref={sliderRef}
                        className={styles.swiper}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        spaceBetween={30}
                        breakpoints={{
                            769: {slidesPerView: 2},
                            768: {slidesPerView: 1}
                        }}
                    >
                        <SwiperSlide className={styles.swiper__item}>
                            <div className={styles.userInfo}>
                                <img src={swiperItemUserLogoSvetlana} className={styles.img}/>
                                <span className={`${styles.name} raleway-24-regular`}>Світлана</span>
                            </div>
                            <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>-8 кг, вирівнялася лопатка
                                крилоподібна, менше турбують головні болі, з’явилася більша
                                гнучкість у тілі, воно стало міцнішим і здоровішим, стала більш стресостійка, навчилася
                                розслаблятися/перемикатися/ абстрагуватися, дякувати собі. А ще все роблю із
                                задоволенням.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Увесь контент, але найбільше зарядки,
                                мінітренування, підкачки спочатку на різні частини тіла, а потім на все тіло,
                                фотоприклади тарілок, позитивна енергія, сподобалися відео з краєвидами.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                участь?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Так, планую продовжити, бо це стало вже
                                моїм стилем життя.
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <div className={styles.userInfo}>
                                <img src={swiperItemUserLogoYana} className={styles.img}/>
                                <span className={`${styles.name} raleway-24-regular`}>Яна</span>
                            </div>
                            <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Кожного року я ходила на масажі, бо з
                                дитинства спина має цілий «букет болячок», але так, як допомогли вправи і проста зарядка
                                від Лени нічого не допомагало.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>На курсі відчувається суцільна тепло,
                                турбота, позитив і підтримка від Лени, що разом з професіоналізмом надихають займатись
                                собою і отримувати чудові результати 😍
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                участь?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Я вже мабудь на пʼятому потоці, і
                                сумнівів немає, що буду подовжувати далі, бо це реальна любов ❤️
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <div className={styles.userInfo}>
                                <img src={swiperItemUserLogoSvetlana} className={styles.img}/>
                                <span className={`${styles.name} raleway-24-regular`}>Світлана</span>
                            </div>
                            <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>-8 кг, вирівнялася лопатка
                                крилоподібна, менше турбують головні болі, з’явилася більша
                                гнучкість у тілі, воно стало міцнішим і здоровішим, стала більш стресостійка, навчилася
                                розслаблятися/перемикатися/ абстрагуватися, дякувати собі. А ще все роблю із
                                задоволенням.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Увесь контент, але найбільше зарядки,
                                мінітренування, підкачки спочатку на різні частини тіла, а потім на все тіло,
                                фотоприклади тарілок, позитивна енергія, сподобалися відео з краєвидами.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                участь?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Так, планую продовжити, бо це стало вже
                                моїм стилем життя.
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <div className={styles.userInfo}>
                                <img src={swiperItemUserLogoYana} className={styles.img}/>
                                <span className={`${styles.name} raleway-24-regular`}>Яна</span>
                            </div>
                            <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Кожного року я ходила на масажі, бо з
                                дитинства спина має цілий «букет болячок», але так, як допомогли вправи і проста зарядка
                                від Лени нічого не допомагало.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>На курсі відчувається суцільна тепло,
                                турбота, позитив і підтримка від Лени, що разом з професіоналізмом надихають займатись
                                собою і отримувати чудові результати 😍
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                участь?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Я вже мабудь на пʼятому потоці, і
                                сумнівів немає, що буду подовжувати далі, бо це реальна любов ❤️
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <div className={styles.userInfo}>
                                <img src={swiperItemUserLogoSvetlana} className={styles.img}/>
                                <span className={`${styles.name} raleway-24-regular`}>Світлана</span>
                            </div>
                            <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>-8 кг, вирівнялася лопатка
                                крилоподібна, менше турбують головні болі, з’явилася більша
                                гнучкість у тілі, воно стало міцнішим і здоровішим, стала більш стресостійка, навчилася
                                розслаблятися/перемикатися/ абстрагуватися, дякувати собі. А ще все роблю із
                                задоволенням.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Увесь контент, але найбільше зарядки,
                                мінітренування, підкачки спочатку на різні частини тіла, а потім на все тіло,
                                фотоприклади тарілок, позитивна енергія, сподобалися відео з краєвидами.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                участь?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Так, планую продовжити, бо це стало вже
                                моїм стилем життя.
                            </p>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <div className={styles.userInfo}>
                                <img src={swiperItemUserLogoYana} className={styles.img}/>
                                <span className={`${styles.name} raleway-24-regular`}>Яна</span>
                            </div>
                            <h3 className={`${styles.question} raleway-16-bold`}>Який результат ти отримала?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Кожного року я ходила на масажі, бо з
                                дитинства спина має цілий «букет болячок», але так, як допомогли вправи і проста зарядка
                                від Лени нічого не допомагало.
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Що сподобалось?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>На курсі відчувається суцільна тепло,
                                турбота, позитив і підтримка від Лени, що разом з професіоналізмом надихають займатись
                                собою і отримувати чудові результати 😍
                            </p>
                            <h3 className={`${styles.question} raleway-16-bold`}>Чи плануєш продовжувати брати
                                участь?</h3>
                            <p className={`${styles.answer} raleway-16-regular`}>Я вже мабудь на пʼятому потоці, і
                                сумнівів немає, що буду подовжувати далі, бо це реальна любов ❤️
                            </p>
                        </SwiperSlide>
                    </Swiper>
                    <div className={styles.prevArrow} onClick={handlePrev}/>
                    <div className={styles.nextArrow} onClick={handleNext}/>
                </div>
            </div>
            <div className={`${styles.resultsSection} container`}>
                <h2 className={`${styles.resultsSection__h2} montserrat-36-black`}>РЕЗУЛЬТАТИ</h2>
                <div className={styles.swiperContainer}>
                    <Swiper
                        className={styles.swiper}
                        modules={[Pagination]}
                        slidesPerView={2.5}
                        breakpoints={{
                            768: {
                                slidesPerView: 5,
                            },
            
                        }}
                    >
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_1} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_2} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_3} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_4} className={styles.img}/>
                        </SwiperSlide>
                        <SwiperSlide className={styles.swiper__item}>
                            <img src={swiperItemResult_5} className={styles.img}/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className={`${styles.tariffsSection} container`} id="tariffs">
                <h2 className={`${styles.tariffsSection__h2} montserrat-36-black`}>
                    ОБЕРІТЬ ТАРИФ, ЯКИЙ ПІДХОДИТЬ САМЕ ТОБІ
                </h2>
                <TariffsSlider/>
            </div>
            <div className={`${styles.popularQuestionsSection} container`} id="popularQuestions">
                <h2 className={`${styles.popularQuestionsSection__h2} montserrat-36-black`}>
                    ВІДПОВІДІ НА ПОПУЛЯРНІ ЗАПИТАННЯ
                </h2>
                <div className={styles.popularQuestionsSection__content}>
                    <CollapsibleQuestionOftenAskedSection/>
                    <img className={styles.img} src={collapsibleQuestionOftenAskedSectionCreattionDemoImg}
                         alt="creattionDemo"
                    />
                </div>
            </div>
            <div className={`${styles.feedbackSection} container`}>
                <div className={styles.feedbackSection__content}>
                    <h2 className={`${styles.feedbackSection__content__h2} montserrat-36-black`}>
                        ЗВОРОТНІЙ ЗВ'ЯЗОК
                    </h2>
                    <p className={`${styles.feedbackSection__content__description} raleway-16-regular`}>
                        Мені дуже важливий зворотній звʼязок, тому якщо в тебе виникли якісь труднощі чи запитання, або
                        ти маєш відгук, пропозицію для вдосконалення платформи - будь ласка залиш свій коментар, дякую.
                    </p>
                </div>
                <div className={`${styles.feedbackSection__form} form`}>
                    <FeedbackForm/>
                </div>
            </div>
        </div>
    )
}

export default HomePage;
