import styles from "./shoppingCart.module.scss";
import React, { useMemo, useState } from "react";
import { useTariffs, buyTariffs, createSignature } from "shared/services/TariffContext";
import WayForPayForm from "shared/components/wayForPayForm/wayForPayForm";
import { useAuth } from 'shared/services/AuthContext';

export const ShoppingCart = () => {
  const { cart, removeTariffFromCart } = useTariffs();
  const [paymentData, setPaymentData] = useState(null);
  const { user } = useAuth();

  const total = useMemo(() => {
    return cart.reduce((totalSum, tariff) => totalSum + tariff.price, 0);
  }, [cart]);

  const handleClick = async () => {
    const orderReferences = await buyTariffs(user, cart.map(tariff => tariff.id));
    const signatureResult = await createSignature(user, orderReferences);
    setPaymentData(signatureResult);
  };

  return (
    <div className={styles.shoppingCart}>
      <h2 className={`raleway-24-bold`}>
        Кошик {cart && cart.length > 0 && <span>({cart.length})</span>}
      </h2>
      <div className={styles.shoppingCart__container}>
        <div className={styles.tariffInfo}>
          <span className={`raleway-16-bold`}>Тариф</span>
          <span className={`raleway-16-bold`}>Ціна</span>
        </div>
        {cart && cart.length > 0 && (
          <>
            <div className={styles.tariffItems}>
              {cart.map((tariff, index) => (
                <div key={index} className={styles.tariffItem}>
                  <div className={styles.tariffItem__left}>
                    <span className={`${styles.title} raleway-16-bold`}>
                      {tariff.title}
                    </span>
                    <span
                      className={`${styles.accessAllowedDays} raleway-16-regular`}
                    >
                      {tariff.accessAllowedDays} днів
                    </span>
                  </div>
                  <span className={`${styles.price} raleway-16-regular`}>
                    {tariff.price}грн
                  </span>
                  <span
                    className={`${styles.close}`}
                    onClick={() => removeTariffFromCart(tariff.id)}
                  />
                </div>
              ))}
            </div>
            <div className={styles.total}>
              <span className={`raleway-16-bold`}>Всього:</span>
              <span className={`raleway-16-bold`}>{total}грн</span>
            </div>
            <button className="btn-brown" onClick={handleClick}>
              Перейти до оплати
            </button>
          </>
        )}
        {!cart.length && (
          <h3 className={`${styles.empty} raleway-16-regular`}>
            У твоєму кошику поки що немає обраних тарифів
          </h3>
        )}
      </div>
      {paymentData && <WayForPayForm data={paymentData} />}
    </div>
  );
};
