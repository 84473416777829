import React from 'react';
import FileIcon from '../../../assets/images/icons/file.svg';
import styles from './downloadFile.module.scss';

const DownloadFile = ({ filePath, fileName = 'файл' }) => {
    const downloadUrl = `${filePath}`;

    return (
        <div className={styles.downloadFile}>
            <h3 className={'raleway-24-regular'}>Завантажити додатковий матеріал</h3>
            <div className={styles.fileContainer}>
                <div className={styles.iconWrapper}>
                    <img src={FileIcon} alt="File Icon" className={styles.fileIcon} />
                </div>
                <div>
                    <p className={`${styles.fileName}`}>{fileName}</p>
                    <a href={downloadUrl} download={fileName} className={styles.downloadLink}>
                        Завантажити
                    </a>
                </div>
            </div>
        </div>
    );
};

export default DownloadFile;
