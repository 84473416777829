import styles from './posterDetailsPage.module.scss'
import {useEffect, useState} from "react";
import {useContent} from "../../../shared/services/ContentContext";
import {Link, useParams} from "react-router-dom";
import VimeoPlayerEmbed from "../../components/vimeoPlayerEmbed/vimeoPlayerEmbed";
import DownloadFile from "../../components/downloadFile/downloadFile";

export const PosterDetailsPage = () => {
    const [detailsPoster, setDetailsPoster] = useState([]);
    const { getAssetDetails } = useContent()
    const { id } = useParams();


    useEffect(() => {
        getAssetDetails(id).then((data) => {
            setDetailsPoster(data)
        });
    }, []);

    return (
        <div className={`${styles.posterDetailsPage} container`}>
            <Link className={styles.posterDetailsPage__link} to="/platform">
                <h2 className={`${styles.posterDetailsPage__title} raleway-32-regular`}>
                    {detailsPoster?.name}
                </h2>
            </Link>
            <div className={styles.player}>
                <VimeoPlayerEmbed id={detailsPoster.videoUrl} />
            </div>
            <div className={styles.descriptionContainer}>
                <h3 className={styles.descriptionContainer__title}>
                    Опис
                </h3>
                <p className={`${styles.descriptionContainer__description} raleway-16-regular`}>
                    {detailsPoster.description}
                </p>
            </div>
            <DownloadFile filePath={detailsPoster.filePath} />
        </div>
    )
}
