import React from 'react';

//             <VimeoPlayerEmbed id={"941702116"} />

const VimeoPlayerEmbed = ({id}) => {
    const htmlString = `<div style="padding:63% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/${id}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Test for website"></iframe></div>`;

    return (
        <div dangerouslySetInnerHTML={{__html: htmlString}}/>
    );
};

export default VimeoPlayerEmbed;
