import styles from "./homePage.module.scss";
import {Link} from "react-router-dom";
import {PostersList} from "../../components/postersList/postersList";
import React from "react";
import tariffNotPaidImg from '../../images/background/tariff-not-paid-bg.svg'
import {useTariffs} from "shared/services/TariffContext";
import {useContent} from 'shared/services/ContentContext';
import { useAuth } from 'shared/services/AuthContext';

export const HomePage = () => {
    const {detailsContentGroups} = useContent()
    const {connectedTariffs} = useTariffs();
    const isDesktop = window.innerWidth >= 768;
    const { user } = useAuth();

    const renderGroupContent = (contentGroup, count) => (
        <div className={styles.contentGroup}>
            <div className={styles.contentGroup__top}>
                <h2 className={`${styles.contentGroup__top__title} raleway-24-bold`}>{contentGroup.name}</h2>
                <Link to={`/platform/content-group/${contentGroup.id}`}
                      className={`${styles.contentGroup__top__all} raleway-16-bold`}>
                    Подивитись все
                </Link>
            </div>
            <PostersList contentGroup={contentGroup} maxLength={count}/>
        </div>
    );


    const renderContent = () => {
        const groupCount = detailsContentGroups.length;
        const hasFolders = detailsContentGroups.some(group => group.folders && group.folders.length > 0);

        if (groupCount >= 2) {
            // якщо прийшло 2 групи або більше показувати по 3 постера з кожної групи на десктопі та по 1 постеру на адаптиві
            // якщо прийшла група з папкою та інші групи, показувати групу з папкою обʼєднавши назву та по одному постеру з групи на десктопі (загально 3 максимум), та один постер з першої групи на адаптиві
            return detailsContentGroups.map(group => {
                    if (group.folders.length) {
                        const firstAssetFromGroup = group.folders.map(groupFromFolder => groupFromFolder.assets[0])
                        const combineTitle = group.folders.map(groupFromFolder => groupFromFolder.name).join(', ');
                        group.assets = firstAssetFromGroup
                        group.name = combineTitle;
                    }
                    return renderGroupContent(group, isDesktop ? 3 : 1)
                }
            );
        } else if (groupCount === 1 && !hasFolders) {
            // якщо прийшла 1 група показувати по 9 постерів на десктопі та 3 на адаптиві
            return renderGroupContent(detailsContentGroups[0], isDesktop ? 9 : 3);
        } else if (groupCount === 1 && hasFolders) {
            // якщо прийла одна група з папкою, показувати усі групи в середені папки по 3 постера на десктопі та 1 на адаптиві
            return detailsContentGroups[0].folders.map(group =>
                renderGroupContent(group, isDesktop ? 3 : 1)
            );
        }
    };

    return (
        <div className={`${styles.homePage} container`}>

            {connectedTariffs?.length !== 0 ? (
                <div>
                    <div className={styles.introductoryWords}>
                        <h2 className={`${styles.introductoryWords__title} raleway-24-bold`}>Привіт {user.name}</h2>
                        <p className={`${styles.introductoryWords__description} raleway-16-regular`}>
                            Це твоя голова сторінка платформи і саме тут ти можеш побачити увесь матеріал, який ти
                            придбав(ла).
                            Бажаю тобі натхнення, дисципліни, відпочинку та продуктивних, а найголовніше тренувань в
                            задоволенням.
                            <br/><br/>
                            Якщо в тебе виникнуть запитання, то в самому низу є посилання
                            на зворотній звʼязок, пиши мені у телеграм, дякую.
                        </p>
                    </div>
                    {renderContent(detailsContentGroups)}
                </div>
            ) : (
                <div className={styles.tariffNotPaid}>
                    <div className={styles.tariffNotPaid__descriptions}>
                        <h2 className={`${styles.tariffNotPaid__descriptions__title} raleway-24-bold`}>Привіт {user.name}</h2>
                        <p className={`${styles.tariffNotPaid__descriptions__description} raleway-16-regular`}>
                            Твій поточний тариф завершився і доступ до матеріалу більше не відображається.
                            <br/><br/>
                            Проте, ти можеш продовжити свій шлях зі мною. Обирай тариф, який тобі актуальний і до
                            зустрічі знову на платформі.
                        </p>
                        <Link to={`/platform/settings?tab=TARIFF`}>
                            <button className={`${styles.tariffNotPaid__descriptions__btn} btn-brown`}>Обрати тариф
                            </button>
                        </Link>
                    </div>
                    <div className={styles.tariffNotPaid__imgContainer}>
                        <img className={styles.tariffNotPaid__imgContainer__img} src={tariffNotPaidImg}
                             alt="tariffNotPaidImg"/>
                    </div>
                </div>
            )}
        </div>
    )
}
