import React, {useState} from 'react';
import AvatarStub from '../../images/icons/avatar-stub.svg'
import styles from './profilePhotoChanger.module.scss'

const ProfilePhotoChanger = ({ onPhotoChange }) => {
    const [photo, setPhoto] = useState(AvatarStub);

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto(reader.result);
            onPhotoChange(reader.result); // Виклик callback функції з новим фото
        };

        reader.readAsDataURL(file);
    };

    return (
        <div>
            <div className={styles.img} style={{backgroundImage: `url(${photo})`}}
                 onClick={() => document.querySelector('input[type="file"]').click()}
            >
                <input type="file" onChange={handlePhotoChange} style={{visibility: 'hidden'}}/>
            </div>
        </div>
    );
};


export default ProfilePhotoChanger;
