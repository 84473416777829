import styles from "./settingsPage.module.scss";
import React, { useState, useEffect } from "react";
import ProfilePhotoChanger from "../../components/profilePhotoChanger/profilePhotoChanger";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { ModalSuccessful } from "../../components/modalSuccessful/modalSuccessful";
import { AddTariffSlider } from "../../components/addTariffSlider/addTariffSlider";
import { ShoppingCart } from "../../components/shoppingCart/shoppingCart";
import { CurrentTariffSlider } from "../../components/currentTariffSlider/currentTariffSlider";
import { TableOfPayments } from "../../components/tableOfPayments/tableOfPayments";
import { useAuth } from "shared/services/AuthContext";
import { useTariffs } from "shared/services/TariffContext";
import { useLocation, useNavigate } from "react-router-dom";

const TABS = {
  PROFILE: "PROFILE",
  PASSWORD: "PASSWORD",
  TARIFF: "TARIFF",
  PAYMENT_HISTORY: "PAYMENT_HISTORY",
};

export const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState(TABS.PROFILE);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    if (tab) {
      setActiveTab(tab);
    }
  }, [location]);

  return (
    <div className={`${styles.settingsPage} container`}>
      <h2 className={`${styles.settingsPage__h2} montserrat-36-black`}>
        Налаштування
      </h2>
      <div className={styles.tabs}>
        <Swiper
          className={styles.swiper}
          modules={[Pagination]}
          spaceBetween={10}
          slidesPerView={"auto"}
          breakpoints={{
            769: { slidesPerView: 5 },
          }}
        >
          <SwiperSlide className={styles.swiper__item}>
            <div
              className={`${styles.tab} ${
                activeTab === TABS.PROFILE ? styles.tab__active : ""
              } raleway-16-regular`}
              onClick={() => setActiveTab(TABS.PROFILE)}
            >
              Профіль
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper__item}>
            <div
              className={`${styles.tab} ${
                activeTab === TABS.PASSWORD ? styles.tab__active : ""
              } raleway-16-regular`}
              onClick={() => setActiveTab(TABS.PASSWORD)}
            >
              Зміна паролю
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper__item}>
            <div
              className={`${styles.tab} ${
                activeTab === TABS.TARIFF ? styles.tab__active : ""
              } raleway-16-regular`}
              onClick={() => setActiveTab(TABS.TARIFF)}
            >
              Тариф
            </div>
          </SwiperSlide>
          <SwiperSlide className={styles.swiper__item}>
            <div
              className={`${styles.tab} ${
                activeTab === TABS.PAYMENT_HISTORY ? styles.tab__active : ""
              } raleway-16-regular`}
              onClick={() => setActiveTab(TABS.PAYMENT_HISTORY)}
            >
              Історія платежів
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      {activeTab === TABS.PROFILE && <UserProfile />}
      {activeTab === TABS.PASSWORD && <ChangePassword />}
      {activeTab === TABS.TARIFF && <Tariff />}
      {activeTab === TABS.PAYMENT_HISTORY && <TableOfPayments />}
    </div>
  );
};

const UserProfile = () => {
  const [focused, setFocused] = useState({});
  const handleFocus = (name) => {
    setFocused({ ...focused, [name]: true });
  };

  const handleBlur = (name) => {
    setFocused({ ...focused, [name]: false });
  };

  const { user, changeUserInfo } = useAuth();

  const [formData, setFormData] = useState({
    avatar: "",
    name: "",
    email: "",
    birthday: "",
    phone: "",
  });

  useEffect(() => {
    if (user) {
      setFormData((formData) => ({
        ...formData,
        name: user.name,
        email: user.email,
        phone: user.phone,
        birthday: user.birthday,
      }));
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAvatarChange = (newAvatar) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      avatar: newAvatar,
    }));
  };

  const handleSubmit = async () => {
    try {
      const isSuccess = await changeUserInfo(formData);
      if (isSuccess) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.profile}>
      <div className={styles.avatar}>
        <ProfilePhotoChanger onPhotoChange={handleAvatarChange} />
        <h3 className={`${styles.h3} raleway-16-bold`}>{user.name}</h3>
      </div>
      <form className={styles.form}>
        <div className={styles.form__inputContainer}>
          {(formData.name || focused.name) && (
            <label
              className={`${styles.form__inputContainer__label} raleway-12-medium`}
            >
              Твоє ім'я
            </label>
          )}
          <input
            className={`${styles.form__inputContainer__input} input input__transparent raleway-16-regular`}
            type="text"
            name="name"
            placeholder={!focused.name ? "Твоє ім'я" : ""}
            value={formData.name}
            onChange={handleInputChange}
            required
            onFocus={() => handleFocus("name")}
            onBlur={() => handleBlur("name")}
          />
        </div>
        <div className={styles.form__inputContainer}>
          {(formData.email || focused.email) && (
            <label
              className={`${styles.form__inputContainer__label} raleway-12-medium`}
            >
              Email
            </label>
          )}
          <input
            className={`${styles.form__inputContainer__input} input input__transparent raleway-16-regular`}
            type="email"
            name="email"
            placeholder={!focused.email ? "Email" : ""}
            value={formData.email}
            onChange={handleInputChange}
            required
            onFocus={() => handleFocus("email")}
            onBlur={() => handleBlur("email")}
          />
        </div>
        <div className={styles.form__inputContainer}>
          {(formData.birthday || focused.birthday) && (
            <label
              className={`${styles.form__inputContainer__label} raleway-12-medium`}
            >
              День народження
            </label>
          )}
          <input
            className={`${styles.form__inputContainer__input} input input__transparent raleway-16-regular`}
            type="text"
            name="birthday"
            placeholder={!focused.birthday ? "День народження" : ""}
            value={formData.birthday}
            onChange={handleInputChange}
            required
            onFocus={() => handleFocus("birthday")}
            onBlur={() => handleBlur("birthday")}
          />
        </div>
        <div className={styles.form__inputContainer}>
          {(formData.phone || focused.phone) && (
            <label
              className={`${styles.form__inputContainer__label} raleway-12-medium`}
            >
              Телефон
            </label>
          )}
          <input
            className={`${styles.form__inputContainer__input} input input__transparent raleway-16-regular`}
            type="tel"
            name="phone"
            placeholder={!focused.phone ? "Телефон" : ""}
            value={formData.phone}
            onChange={handleInputChange}
            required
            onFocus={() => handleFocus("phone")}
            onBlur={() => handleBlur("phone")}
          />
        </div>
      </form>
      <button
        className={`${styles.btn} btn-brown`}
        onClick={() => handleSubmit()}
      >
        Зберегти
      </button>
      {isModalOpen && (
        <ModalSuccessful
          description="Зміни у твоєму профілі були збережені."
          onBtnClick={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    repeatPassword: "",
  });

  const [focused, setFocused] = useState({});
  const handleFocus = (name) => {
    setFocused({ ...focused, [name]: true });
  };

  const handleBlur = (name) => {
    setFocused({ ...focused, [name]: false });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { setNewPassword } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.password !== formData.repeatPassword || !formData.password.length) {
      return;
    }
    try {
      const isSuccess = await setNewPassword(formData);
      if (isSuccess) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={styles.changePassword}>
      <h3 className={`raleway-16-bold`}>
        Для зміни паролю, заповни наступні поля:
      </h3>
      <form className={styles.form}>
        <div className={styles.form__inputContainer}>
          {(formData.password || focused.password) && (
            <label
              className={`${styles.form__inputContainer__label} raleway-12-medium`}
            >
              Новий пароль
            </label>
          )}
          <input
            className={`${styles.form__inputContainer__input} input input__transparent raleway-16-regular`}
            type="password"
            name="password"
            placeholder={!focused.password ? "Новий пароль" : ""}
            value={formData.password}
            onChange={handleInputChange}
            required
            onFocus={() => handleFocus("password")}
            onBlur={() => handleBlur("password")}
          />
        </div>
        <div className={styles.form__inputContainer}>
          {(formData.repeatPassword || focused.repeatPassword) && (
            <label
              className={`${styles.form__inputContainer__label} raleway-12-medium`}
            >
              Повтор паролю
            </label>
          )}
          <input
            className={`${styles.form__inputContainer__input} input input__transparent raleway-16-regular`}
            type="password"
            name="repeatPassword"
            placeholder={!focused.repeatPassword ? "Повтор паролю" : ""}
            value={formData.repeatPassword}
            onChange={handleInputChange}
            required
            onFocus={() => handleFocus("repeatPassword")}
            onBlur={() => handleBlur("repeatPassword")}
          />
        </div>
        <button
          className={`${styles.btn} btn-brown`}
          onClick={handleSubmit}
        >
          Зберегти
        </button>
        {isModalOpen && (
          <ModalSuccessful
            description="Пароль змінено."
            onBtnClick={() => setIsModalOpen(false)}
          />
        )}
      </form>
    </div>
  );
};

const Tariff = () => {
  const TariffComponents = {
    AddTariff: "addTariff",
    CurrentTariff: "currentTariff",
  };
  const [tariffCurrentComponent, setTariffCurrentComponent] = useState(
    TariffComponents.CurrentTariff
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { connectedTariffs } = useTariffs();
  const location = useLocation();
  const navigate = useNavigate();

  const hasActiveTariff = connectedTariffs && connectedTariffs.some(
    (tariff) => tariff.active === true
  );

  const tariffsPrice = connectedTariffs
      ? connectedTariffs.reduce((total, tariff) => total + Number(tariff.price), 0)
      : 0;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get("status");

    if (paymentStatus) {
      setIsModalOpen(true)
      queryParams.delete("status");
      navigate({
        search: queryParams.toString(),
      }, { replace: true });
    }
  }, [location, navigate]);

  return (
    <div className={styles.tariff}>
      {tariffCurrentComponent === "currentTariff" &&
        (connectedTariffs && connectedTariffs.length !== 0 ? (
          <div className={styles.currentTariffContainer}>
            <div className={styles.currentTariffContainer_left}>
              <CurrentTariffSlider />
            </div>
            <div className={styles.currentTariffContainer_right}>
              {hasActiveTariff && (
                <div className={styles.block_one}>
                  <h2 className={`${styles.title} raleway-24-bold`}>Оплата</h2>
                  <p className={`${styles.description} raleway-16-regular`}>
                    Ти отримуєш доступ до тарифу за щомісячну оплату у розмірі:{" "}
                    <span
                      className={`${styles.description__price} raleway-16-bold`}
                    >
                      {tariffsPrice}грн
                    </span>
                  </p>
                </div>
              )}
              <div className={styles.block_two}>
                <h2 className={`${styles.title} raleway-24-bold`}>
                  Додати ще тарифи
                </h2>
                <p className={`${styles.description} raleway-16-regular`}>
                  Доповни свій тариф та урізноманітни роботу над собою. Більше
                  матеріалу - більше можливостей!
                </p>
                <button
                  className={`${styles.btn} btn-brown`}
                  onClick={() =>
                    setTariffCurrentComponent(TariffComponents.AddTariff)
                  }
                >
                  Додати тариф
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.currentTariffEnded}>
            <h2 className={`${styles.title} raleway-24-bold`}>
              Поточний тариф
            </h2>
            <p className={`${styles.description} raleway-16-regular`}>
              Твій поточний тариф завершився, і доступ до тренувань більше не
              відображається.
              <br />
              <br /> Проте, ти можеш продовжити свій тренувальний шлях – обери і
              придбай інший тариф, щоб продовжити заняття та досягати нових
              спортивних вершин.
            </p>
            <button
              className={`${styles.btn} btn-brown`}
              onClick={() =>
                setTariffCurrentComponent(TariffComponents.AddTariff)
              }
            >
              Додати тариф
            </button>
          </div>
        ))}
      {tariffCurrentComponent === "addTariff" && (
        <div className={styles.addTariffContainer}>
          <div
            className={`${styles.addTariffContainer__goBack} raleway-16-bold`}
            onClick={() =>
              setTariffCurrentComponent(TariffComponents.CurrentTariff)
            }
          >
            Повернутись
          </div>
          <div className={styles.addTariffContainer_left}>
            <AddTariffSlider />
          </div>
          <div className={styles.addTariffContainer_right}>
            <ShoppingCart />
          </div>
        </div>
      )}
       {isModalOpen && (
        <ModalSuccessful
          description="Вітаю! Новий тариф успішно придбаний. Тепер у тебе є доступ до платформи, тому забігай - тебе чекає корисний матеріал."
          onBtnClick={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};
