import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./addTariffSlider.module.scss";

import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTariffs } from "shared/services/TariffContext";

export const AddTariffSlider = () => {
  const sliderRef = useRef(null);
  const [selectedTariff, setSelectedTariff] = useState("first");
  const { getAvailableTariff, availableTariffs, addTariffToCart } = useTariffs();

  useEffect(() => {
    getAvailableTariff();
  }, []);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    setSelectedTariff(null);
    sliderRef.current.swiper.slidePrev();
    if (sliderRef?.current?.swiper.activeIndex === 0) {
      setSelectedTariff("first");
    }
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    setSelectedTariff(null);
    sliderRef.current.swiper.slideNext();
    if (sliderRef?.current?.swiper.activeIndex === availableTariffs.length - 1) {
      setSelectedTariff("last");
    }
  }, [availableTariffs]);

  return (
    <div className={styles.tariffsSliderContainer}>
      <div className={styles.top}>
        <h2 className={`${styles.title} raleway-24-bold`}>Додати тариф</h2>
        {availableTariffs && availableTariffs.length > 1 && (
          <>
            <div
              className={`${styles.prevArrow} ${
                selectedTariff === "first" ? styles.inactive : ""
              }`}
              onClick={handlePrev}
            />
            <div
              className={`${styles.nextArrow} ${
                selectedTariff === "last" ? styles.inactive : ""
              }`}
              onClick={handleNext}
            />
          </>
        )}
      </div>
      <div className={styles.tariffsSlider}>
        <Swiper
          ref={sliderRef}
          className={styles.swiper}
          pagination={{
            clickable: true,
          }}
          spaceBetween={30}
          slidesPerView={1}
          onSlideChange={(swiper) => {
            if (swiper.activeIndex === 0) {
              setSelectedTariff("first");
            } else if (swiper.activeIndex === availableTariffs.length - 1) {
              setSelectedTariff("last");
            } else {
              setSelectedTariff(null);
            }
          }}
        >
          {availableTariffs &&
              availableTariffs.map((tariff) => (
              <SwiperSlide key={tariff.id} className={styles.swiper__item}>
                <div className={styles.content}>
                  <h3 className={`${styles.content__h3} montserrat-36-black`}>
                    {tariff.title}
                  </h3>
                  <div className={styles.content__description}>
                    <h4 className={`${styles.questionTitle} raleway-16-bold`}>
                      Що входить у тариф:
                    </h4>
                    {tariff.includedInTariff.map((item) => (
                      <p className={`${styles.answerText} raleway-16-regular`}>
                        {item}
                      </p>
                    ))}
                    <h4 className={`${styles.questionTitle} raleway-16-bold`}>
                      Для чого вам такий тип тренувань?
                    </h4>
                    {tariff.reasonsToBuy.map((item) => (
                      <p className={`${styles.answerText} raleway-16-regular`}>
                        {item}
                      </p>
                    ))}
                  </div>
                  <span
                    className={`${styles.content__price} montserrat-36-black`}
                  >
                    {tariff.price} ГРН
                  </span>
                  <p
                    className={`${styles.content__accessAllowedDays} raleway-24-regular`}
                  >
                    ДОСТУП {tariff.accessAllowedDays} ДНІВ
                  </p>
                  <button
                    className={`${styles.content__btn} btn-brown`}
                    onClick={() => addTariffToCart(tariff.id)}
                  >
                    Додати в кошик
                  </button>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
};
